import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import logo from "../images/logo-sign.svg";
import * as s from "./footer.module.css";

export default function Footer() {
  const year = new Date().getFullYear();
  const legalNodes = useStaticQuery(graphql`
    query legalPages {
      allSanityLegal(sort: {orderRank: ASC}) {
        nodes {
          name
          slug {
            current
          }
        }
      }
    }
  `).allSanityLegal.nodes;
  return (
    <footer className={s.wrapper}>
      <div className={s.column}>
        <Link className={s.logo} to="/">
          <img className={s.logoImage} src={logo} alt="" />
        </Link>
        <div className={s.nav}>
          <Link to="/pricing/">Pricing</Link>
          <Link to="/contact/">Contact</Link>
          {legalNodes.map(({ slug, name }) => (
            <Link key={slug.current} to={`/${slug.current}/`}>{name}</Link>
          ))}
        </div>
        <div className={s.copy}>
          <p>© {year} Deltaplan Technologies</p>
        </div>
      </div>
    </footer>
  )
}