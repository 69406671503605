import React, { useRef, useState, useLayoutEffect, useEffect } from 'react';
import * as s from './cookiesConsent.module.css';
import { Link } from 'gatsby';

export default function CookiesConsent() {
  const name = "cookiesConsent";
  const [show, setShow] = useState(false);
  const [settings, setSettings] = useState(false);

  useLayoutEffect(() => {
    setShow(!getCookie(name));
  }, [])

  useLayoutEffect(() => {
    const cookie = getCookie(name);
    if (cookie?.marketing) {
      window.fbq('consent', 'grant');
    }
  }, [show])

  const onAccept = () => {
    setCookie(name, acceptAll);
    setShow(false);
  }
  const onReject = () => {
    setCookie(name, rejectAll);
    setShow(false);
  }
  const onCustomize = () => {
    setSettings(true);
  }
  const onCustomizeClose = () => {
    setSettings(false);
  }
  const onSubmit = (val) => {
    setCookie(name, val);
    setShow(false);
    setSettings(false);
  }

  return (
    <>
      <SettingsModal open={settings} onClose={onCustomizeClose} onSubmit={onSubmit} />
      {show && !settings ? (
        <div className={s.wrapper}>
          <p>We use cookies to enhance your experience and analyze site traffic. By accepting, you agree to the use of cookies in line with our <Link className={s.link} to="/cookies">Cookie Policy</Link>.
          </p>
          <div className={s.options}>
            <button className={`${s.button} ${s.reject}`} onClick={onCustomize}>Customize</button>
            <button className={`${s.button} ${s.reject}`} onClick={onReject}>Reject</button>
            <button className={`${s.button} ${s.accept}`} onClick={onAccept}>Accept</button>
          </div>
        </div>
      ) : null}
    </>
  )
}

function SettingsModal({ open, onClose, onSubmit }) {
  const ref = useRef();
  useEffect(() => {
    if (open) {
      ref.current.showModal();
      document.body.style.overflow = "hidden";
    } else {
      ref.current.close();
      document.body.style.overflow = "auto";
    }
  }, [open])
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const vals = [...data.keys()].reduce((acc, key) => { acc[key] = true; return acc }, {});
    const result = { ...rejectAll, ...vals };
    onSubmit(result);
    console.log({ ...rejectAll, ...vals });
  }
  return (
    <dialog ref={ref} className={s.dialog} onClose={onClose}>
      <button className={s.close} onClick={onClose}>
        <svg viewBox='0 0 24 24'>
          <path d="M3,3L21,21M3,21L21,3" fill='none' />
        </svg>
        Close
      </button>
      <div className={s.desc}>
        <h1>Cookie preferences</h1>
        <p>This website uses cookies, pixel tags, and local storage for its core functionality, analytics, and marketing purposes. We may use our own cookies as well as some from third parties. To find out more, read our <Link className={s.link} to="/cookies" onClick={onClose}>Cookie Policy</Link>.</p>
      </div>
      <form onSubmit={handleSubmit}>
        <Option
          disabled={true}
          name="essential"
          title="Essential"
          description="These items are essential for the proper functioning of the website."
        />
        <Option
          name="analytics"
          title="Analytics"
          description="These items help us measure performance, track visitor engagement, and detect potential technical issues."
        />
        <Option
          name="marketing"
          title="Marketing"
          description="These are third-party cookies from our advertising partners that help deliver personalized ads, limit ad frequency, and measure ad campaign effectiveness."
        />
        <button type='submit' className={`${s.button} ${s.accept} ${s.submit}`}>Save preferences</button>
      </form>
    </dialog>
  )
}

function Option({ name, title, description, disabled }) {
  return (
    <label className={s.option}>
      <input type='checkbox' name={name} defaultChecked={disabled} disabled={disabled} />
      <div className={s.toggle} />
      <h2 className={s.optionTitle}>{title}</h2>
      <p className={s.optionDescription}>{description}</p>
    </label>
  )
}

function setCookie(name, val) {
  if (typeof document !== "undefined") {
    const value = JSON.stringify(val);
    const domain = process.env.GATSBY_DOMAIN;
    const maxAge = 60 * 60 * 24 * 365;
    document.cookie = `${name}=${value};domain=${domain};max-age=${maxAge}`;
  }
}
function getCookie(name) {
  if (typeof document !== "undefined") {
    const cookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith(name + "="))
    ?.split("=")[1];
    if (cookie) {
      return JSON.parse(cookie)
    } else {
      return null;
    }
  }
}

const acceptAll = {
  analytics: true,
  marketing: true,
};
const rejectAll = {
  analytics: false,
  marketing: false,
};