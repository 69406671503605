import React, { useEffect, useRef, useState } from "react";
import { Link } from "gatsby";
import * as s from "./header.module.css";
import { ReactComponent as Logo } from "../images/logo.svg";


export default function Header() {
  const [showNav, setShowNav] = useState(false);
  const timeout = useRef();
  const onClick = () => setShowNav(!showNav);
  const closeMenu = () => setShowNav(false);

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth >= 660) setShowNav(false);
      if (timeout.current) clearTimeout(timeout.current);
      document.body.classList.toggle(s.noTransition, true)
      timeout.current = setTimeout(() => document.body.classList.toggle(s.noTransition, false));
    }
    window.addEventListener("resize", handler);
  }, [])
  
  const className = [
    s.header,
    showNav && s.showMenu
  ].filter(Boolean).join(" ");

  return (
    <header className={className}>
      <Link to="/" className={s.logo} onClick={closeMenu} >
        <Logo alt="Logo" draggable={false} />
      </Link>
      <div className={s.endAlign}>
        <nav className={s.menu}>
          <Link onClick={closeMenu} to="/pricing" className={s.link}>Pricing</Link>
          <Link onClick={closeMenu} to="/contact" className={s.link}>Contact us</Link>
          <a href="https://app.deltaplan.io/login" className={s.link}>Log in</a>
          <a href="https://app.deltaplan.io/signup" className={s.cta}>Sign up</a>
        </nav>
        <button type="button" className={s.menuIcon} onClick={onClick}>
          <svg draggable={false} viewBox="0 0 24 24" stroke="black" strokeWidth="2">
            <path d="M2,6h20" />
            <path d="M2,12h20" />
            <path d="M2,18h20" />
          </svg>
        </button>
      </div>
    </header>
  )
}