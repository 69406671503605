// extracted by mini-css-extract-plugin
export var accept = "cookiesConsent-module--accept--f6af6";
export var button = "cookiesConsent-module--button--8664d";
export var close = "cookiesConsent-module--close--56e31";
export var desc = "cookiesConsent-module--desc--ef045";
export var dialog = "cookiesConsent-module--dialog--8e496";
export var link = "cookiesConsent-module--link--6f105";
export var option = "cookiesConsent-module--option--bfc49";
export var optionDescription = "cookiesConsent-module--optionDescription--2cb4a";
export var optionTitle = "cookiesConsent-module--optionTitle--9b35c";
export var options = "cookiesConsent-module--options--29d0b";
export var reject = "cookiesConsent-module--reject--b547c";
export var submit = "cookiesConsent-module--submit--ceb36";
export var toggle = "cookiesConsent-module--toggle--d5859";
export var wrapper = "cookiesConsent-module--wrapper--f91bb";