import * as React from "react";
import Header from "./header";
import Footer from "./footer";
import CookiesConsent from "./cookiesConsent";
import * as s from "./layout.module.css";

export default function Layout({ children }) {
  return (
    <div className={s.pageWrapper}>
      <CookiesConsent />
      <Header />
      <main className={s.main}>
        {children}
      </main>
      <Footer />
    </div>
  )
}